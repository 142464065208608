'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.units.controller:UnitGroupUserCtrl

 # @description

###
class UnitGroupUserCtrl
  ### @ngInject ###
  constructor:(
    id
    $mdDialog
    UnitGroupUsersManager
    UnitGroupsManager
    $q
    $log
  ) ->
    @progress = true
    @model = {}

    init = =>
      @id = id
      @fields = UnitGroupUsersManager.getNewForm()

      @load = load
      @addUser = addUser
      @removeUser = removeUser
      @cancel = cancel
      @submit = submit

      @load()
    ##########

    addUser = ->
      @progress = true
      UnitGroupUsersManager.setParentId(@id)
      $q.when(UnitGroupsManager.addUser(@id, @model)).then =>
        @load()

    removeUser = (contactId) ->
      @progress = true
      UnitGroupUsersManager.setParentId(@id)
      $q.when(UnitGroupUsersManager.delete(contactId)).then =>
        @load()

    load = ->
      UnitGroupUsersManager.setParentId(@id)
      UnitGroupUsersManager.getFullList().then((users) =>
        @items = []
        for user in users
          UnitGroupUsersManager.one(user.id).then((completeUser) =>
            $log.debug completeUser
            if !completeUser.deletedAt
              @items.push(completeUser)
          )

#        @items = _.filter items, (item) -> !item.deletedAt?
        @progress = false
        @form = {}
      )
    cancel = ->
      $mdDialog.cancel()

    submit = ->
      console.log 'submit'


    init()
angular
  .module('mundoAdmin.units')
  .controller 'UnitGroupUserCtrl', UnitGroupUserCtrl
